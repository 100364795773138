<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">一企一档列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="handlAdd()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
              width="100px"
            />
            <el-table-column
              label="企业名称"
              align="left"
              prop="enterpriseName"
              show-overflow-tooltip
            />
            <el-table-column
              label="社会信用统一代码"
              align="left"
              width="180px"
              prop="compNo"
              show-overflow-tooltip
            />
            <el-table-column
              label="安管人员"
              align="left"
              prop="safetyManager"
              show-overflow-tooltip
            />
            <el-table-column
              label="安全生产管理机构负责人"
              align="left"
              prop="safetyPrincipal"
              show-overflow-tooltip
            />
            <el-table-column
              label="添加时间"
              align="left"
              prop="createTime"
              width="150px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.createTime | moment }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="210px">
              <div slot-scope="scope" class="flexcc">
                <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="handlEdit(scope.row, 'edit')"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="
                    handlArchivesOfEachIssue(
                      scope.row.enterpriseId,
                      scope.row.enterpriseName
                    )
                  "
                  >每期档案</el-button
                >
                <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="
                    handlEnterpriseArchives(
                      scope.row.enterpriseId,
                      scope.row.enterpriseName,
                      scope.row.compId
                    )
                  "
                  >企业档案</el-button
                >
                <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="handlDelete(scope.row.enterpriseId)"
                  >删除</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      @close="close"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="170px"
          class="demo-ruleForm"
        >
          <el-form-item label="企业名称" prop="enterpriseName">
            <el-input v-model="ruleForm.enterpriseName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="社会信用统一代码" prop="compNo">
            <el-input v-model="ruleForm.compNo" size="small"></el-input>
          </el-form-item>
          <el-form-item label="安管人员" prop="safetyManager">
            <el-input v-model="ruleForm.safetyManager" size="small"></el-input>
          </el-form-item>
          <el-form-item label="安全生产管理机构负责人" prop="safetyPrincipal">
            <el-input
              v-model="ruleForm.safetyPrincipal"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="安管人员手签文件"
            prop="safetyManagerSignatureUrl"
            class="safeItem"
          >
            <el-upload
              :on-change="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  ruleForm.safetyManagerSignatureUrl ||
                    require('@/assets/develop.png')
                "
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
            <el-button
              @click="removeSafeMUrl()"
              size="small"
              v-if="ruleForm.safetyManagerSignatureUrl"
              >删 除</el-button
            >
          </el-form-item>
          <el-form-item
            label="安全生产管理机构负责人手签文件"
            prop="safetyPrincipalSignatureUrl"
            class="safeItem"
          >
            <el-upload
              :on-change="handleAvatarSuccess3"
              :before-upload="beforeAvatarUpload2"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  ruleForm.safetyPrincipalSignatureUrl ||
                    require('@/assets/develop.png')
                "
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
            <el-button
              @click="removeSafePUrl()"
              size="small"
              v-if="ruleForm.safetyPrincipalSignatureUrl"
              >删 除</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button class="bgc-bv" @click="doSave('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "OneEnterpriseOneFile",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      title: "",
      enterpriseName: "", //题库名称
      ruleForm: {
        enterpriseName: "",
        compNo: "",
        compId: "",
        safetyManager: "",
        safetyPrincipal: "",
        safetyManagerSignatureUrl: "",
        safetyPrincipalSignatureUrl: "",
        safetyManagerSignatureKey: "",
        safetyPrincipalSignatureKey: "",
      },
      rules: {
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        compNo: [{ required: true, trigger: "blur", validator: creditCode }],
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.enterpriseName) {
        params.enterpriseName = this.enterpriseName;
      }
      this.doFetch({
        url: "/eighteenEnterprise/enterprise/page",
        params,
        pageNum,
      });
    },
    /* 新增 */
    handlAdd() {
      this.dialogVisible = true;
      this.title = "新增";
    },
    doSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmar = {
            compNo: this.ruleForm.compNo,
            enterpriseName: this.ruleForm.enterpriseName,
            safetyManager: this.ruleForm.safetyManager,
            safetyPrincipal: this.ruleForm.safetyPrincipal,
            safetyManagerSignature: this.ruleForm.safetyManagerSignatureKey,
            safetyPrincipalSignature: this.ruleForm.safetyPrincipalSignatureKey,
          };
          if (this.ruleForm.stu == "edit") {
            parmar.enterpriseId = this.ruleForm.enterpriseId;
          }
          console.log(parmar)
          this.$post(
            this.ruleForm.stu == "edit"
              ? "/eighteenEnterprise/enterprise/modify"
              : "/eighteenEnterprise/enterprise/insert",
            parmar
          )
            .then((ret) => {
              this.dialogVisible = false;
              this.getData();
            })
            .catch((err) => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {};
    },
    /* 编辑 */
    handlEdit(row, stu) {
      this.dialogVisible = true;
      console.log(stu);
      this.title = "修改";
      this.$post("/eighteenEnterprise/enterprise/getInfo", {
        enterpriseId: row.enterpriseId,
      }).then((ret) => {
        this.ruleForm = {
          stu: stu,
          safetyManagerSignatureUrl: ret.data.safetyManagerSignatureUrl || "",
          safetyManagerSignatureKey: ret.data.safetyManagerSignature || "",
          safetyPrincipalSignatureUrl: ret.data.safetyPrincipalSignatureUrl || "",
          safetyPrincipalSignatureKey: ret.data.safetyPrincipalSignature || "",
          compNo: ret.data.compNo,
          enterpriseName: ret.data.enterpriseName,
          enterpriseId: ret.data.enterpriseId,
          safetyManager: ret.data.safetyManager,
          safetyPrincipal: ret.data.safetyPrincipal,
        };
      });
      //  this.ruleForm = {
      //      compNo,
      //      enterpriseName,
      //      enterpriseId,
      //      safetyManager,
      //      safetyPrincipal,
      //      stu
      //  }
    },
    /* 每期档案 */
    handlArchivesOfEachIssue(enterpriseId, enterpriseName) {
      this.$router.push({
        path: "/web/ArchivesOfEachIssue",
        query: {
          enterpriseId,
          enterpriseName,
        },
      });
    },
    /* 企业档案 */
    handlEnterpriseArchives(enterpriseId, enterpriseName, compId) {
      this.$router.push({
        path: "/web/OneEnterpriseArchives",
        query: {
          enterpriseId,
          enterpriseName,
          compId,
        },
      });
    },
    /* 删除 */
    handlDelete(enterpriseId) {
      this.doDel({
        url: "/eighteenEnterprise/enterprise/delete",
        msg: "确定删除该企业吗？删除后，该企业下所有档案都将被删除。",
        ps: {
          type: "post",
          data: { enterpriseId },
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.safetyManagerSignatureUrl = result.data.fileURL;
            this.ruleForm.safetyManagerSignatureKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    removeSafeMUrl() {
      this.ruleForm.safetyManagerSignatureKey = "";
      this.ruleForm.safetyManagerSignatureUrl = "";
    },
    handleAvatarSuccess3(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.safetyPrincipalSignatureUrl = result.data.fileURL;
            this.ruleForm.safetyPrincipalSignatureKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    removeSafePUrl() {
      this.ruleForm.safetyPrincipalSignatureKey = "";
      this.ruleForm.safetyPrincipalSignatureUrl = "";
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.safeItem {
  .el-form-item__content {
    display: flex;
    align-items: flex-end;
    .img-el-upload {
      min-width: 275px;
    }
  }
}
</style>
